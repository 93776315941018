var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: ["cs-radio-group", { disabled: _vm.disabled }],
      style: {
        "--label-radio-width": _vm.width,
        "--label-inner-radio-width": _vm.innerWidth,
      },
    },
    _vm._l(_vm.items, function (item, key) {
      return _c(
        "div",
        {
          key: key + "label",
          on: {
            click: function ($event) {
              return _vm.checkRadio(key)
            },
          },
        },
        [
          _c("label", {
            class: { active: String(_vm.value) === key },
            attrs: { for: _vm.radioName + "radio" + key },
          }),
          _c("span", [_vm._v(_vm._s(item))]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }